<template>
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark">Rider Salary</h1>
                    </div><!-- /.col -->
                    <div class="col-sm-6">
                        <router-link to="rider-monthly-salary-create" class="btn btn-info float-right"><i class="fas fa-plus-circle"></i> Create</router-link>
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <form v-on:keyup.enter="searchData">
                                    <div class="row">
                                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-4 col-xs-12">
                                            <div class="form-group">
                                                <label for="id">Id</label>
                                                <input type="text" id="id" v-model="search.id" placeholder="Enter ID" class="form-control" />
                                            </div>
                                        </div>
                                         <div class="col-xl-2 col-lg-2 col-md-2 col-sm-4 col-xs-12"  v-if="authUser.role_id <= 2">
                                            <div class="form-group">
                                                <label for="hub_id">Hub</label>
                                                <select2 v-model="search.hub_id" placeholder="Select" :options="commonObj.hubList"></select2>
                                            </div>
                                        </div>
                                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-4 col-xs-12">
                                            <div class="form-group">
                                                <label for="year">Year</label>
                                                <input type="text" id="year" v-model="search.year" placeholder="Example 2022" class="form-control" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"/>
                                            </div>
                                        </div>
                                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-4 col-xs-12">
                                            <div class="form-group">
                                                <label for="month">Month</label>
                                                <select2 v-model="search.month" placeholder="Select" :options="monthList"></select2>
                                            </div>
                                        </div>
                                        <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12">
                                            <div class="form-group">
                                                <button class="btn btn-info btn-sm" type="submit" @click.prevent="searchData" style="margin-top:28px;"><i class="fa fa-search"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="card-body">
                                <b-overlay :show="loader">
                                    <div class="table-responsive">
                                        <table class="table table-bordered table-striped table-sm">
                                            <thead>
                                                <tr class="text-center">
                                                    <th>Total Salary Generated</th>
                                                    <th>Total Salary Amount</th>
                                                    <th>Total Advacned Amount</th>
                                                    <th>Final Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="summary">
                                                <tr class="text-center text-bold">
                                                    <td>{{ summary.total_salary }}</td>
                                                    <td>{{ summary.total_salary_amount }}</td>
                                                    <td>{{ summary.total_salary_advanced }}</td>
                                                    <td>{{ summary.total_final_amount }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </b-overlay>
                            </div>
                            <div class="card-body mt-20">
                                <b-overlay :show="loader">
                                    <div class="table-responsive">
                                        <div style="width:100%; overflow-x:auto;">
                                            <table class="table table-bordered table-striped table-sm text-center" style="width:2000px">
                                                <thead>
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>Year</th>
                                                        <th>Month</th>
                                                        <th>Total Rider</th>
                                                        <th>Total Salary</th>
                                                        <th>Salary Advacned</th>
                                                        <th>Final Amount</th>
                                                        <th>Make Date</th>
                                                        <th>Paid Date</th>
                                                        <th>Status</th>
                                                        <th style="text-align:center;">Action</th>
                                                    </tr>
                                                </thead>
                                                <tfoot>
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>Year</th>
                                                        <th>Month</th>
                                                        <th>Total Rider</th>
                                                        <th>Total Salary</th>
                                                        <th>Salary Advacned</th>
                                                        <th>Final Amount</th>
                                                        <th>Make Date</th>
                                                        <th>Paid Date</th>
                                                        <th style="text-align:center;">Action</th>
                                                    </tr>
                                                </tfoot>
                                                <tbody>
                                                    <tr v-for="(item, index) in listData" :key="index">
                                                        <td>{{ item.id }}</td>
                                                        <td>{{ item.year }}</td>
                                                        <td>{{ item.month_name }}</td>
                                                        <td>{{ item.total_rider }}</td>
                                                        <td>{{ item.total_salary }}</td>
                                                        <td>{{ item.salary_advanced }}</td>
                                                        <td>{{ item.final_amount }}</td>
                                                        <td>{{ item.date | dateformat}}</td>
                                                        <td>{{ item.status == 2 ? (item.updated_at | dateformat) : '' }}</td>
                                                        <td :class="'salary-status-' + item.status">{{ item.status == 1 ? 'Maked' : 'Paid' }}</td>
                                                        <td style="text-align:center;">        
                                                            <button class="btn btn-warning btn-sm mr-1" @click="showDetails(item)" title="Show Details"><i class="fas fa-eye"></i></button>
                                                            <!-- <button class="btn btn-warning btn-sm mr-1" @click="editModal(item)" title="Edit"><i class="fas fa-pencil-alt"></i></button> -->
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="mt-3">
                                        <b-pagination
                                            v-model="pagination.currentPage"
                                            :per-page="pagination.perPage"
                                            :total-rows="pagination.totalRows"
                                            @input="searchData"
                                        />
                                    </div>
                                </b-overlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- /.container-fluid -->
        </section>
    </div>
</template>
<script>
    import config from '@/config'
    import Select2 from 'v-select2-component'
    export default {
        name:'Rider',
        components:{
            'select2': Select2
        },
        data () {
            return {  
                loader: false, 
                riders:{
                    data: []
                },
                search: {
                    id: '',
                    hub_id: 0,
                    year: '',
                    month: 0
                },
                summary: null,
                vehicles:[
                    {value:1, text: 'Bike'},
                    {value:2, text: 'Cycle'},
                    {value:3, text: 'Covered Van'}
                ],
                form_edit:'',
                errors:[],
                createModal:false,
                riderEditModal:false,
                riderDeleteModal:false,
                rider_id:'',
                key:'',
                pagination: {
                    currentPage: 1,
                    totalRows: 0,
                    perPage: this.$store.state.commonObj.perPage,
                    slOffset: 1
                }
            }
        },
        created(){
            this.loadSummary()
            this.loadData();
        },
        watch: {
            stateReload : function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.loadSummary()
                    this.loadData()
                }
            }
        },
        computed : {
            stateReload () {
                return this.$store.state.stateReload
            },
            listData () {
                return this.$store.state.list
            },
            commonObj () {
                return this.$store.state.commonObj
            },
            monthList () {
                return this.$store.state.monthList
            },
            authUser () {
                return this.$store.state.authUser
            }
        },
        methods:{
            searchData () {
                this.loadSummary()         
                this.loadData()         
            },
            async loadSummary () {
                this.loader = true
                const response = await config.getData('/rider-monthly-salary/summary', this.search)
                this.loader = false
                this.summary = response.data          
            },
            async loadData(){     
                this.loader = true 
                const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })          
                const response = await config.getData('/rider-monthly-salary', params)
                this.loader = false
                if (response.success) {
                    this.$store.dispatch('setList', this.getRelationalData(response.data.data))
                    this.paginationData(response.data)
                } else {
                    this.$store.dispatch('setList', [])
                } 
            },
            getRelationalData (data) {
                return data.map(item => {
                    const month = this.$store.state.monthList.find(month => month.id === item.month)
                    const tmpObj = { month_name : typeof month !== 'undefined' ? month.text : '' }
                    return Object.assign(item, tmpObj)
                })
            },
            paginationData (data) {
                this.pagination.currentPage = data.current_page
                this.pagination.totalRows = data.total
                this.pagination.perPage = data.per_page
                this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
            },
            showDetails (item) {
                // this.$router.push('/rider-salary-details')
                this.$router.push({ path: '/rider-salary-details', query: { id: item.id } })
            }
        }
    }
</script>
<style>
    .w-35{
        width:35px;
    }
</style>